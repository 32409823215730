export const FONTS = {
  INTER: `'Inter', sans-serif`,
  INTER_TIGHT: `'Inter Tight', sans-serif`,
  SOURCE_CODE_PRO: `"Source Code Pro", monospace`,
}

export const TYPOGRAPHY = {
  INTER: {
    fontFamily: FONTS.INTER,
  },
  INTER_TIGHT: {
    fontFamily: FONTS.INTER_TIGHT,
  },
  SOURCE_CODE_PRO: {
    fontFamily: FONTS.SOURCE_CODE_PRO,
  },
}
